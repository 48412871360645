import { datadogLogs } from '@datadog/browser-logs'
import { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom"
import { FilesService } from '@domain/services';
import { filterNonImages, filterLargeFiles, filterFileTypes, prepareFilesForUploading, checkIsFeedback, } from '@domain/helpers';
import {
  GREENCARD_DELETED,
  GREENCARD_UPLOADED,
  IMAGES_UPLOADED,
  INTAKE_IMAGES_UPLOADED,
  IMAGE_DELETED,
  INTAKE_IMAGE_DELETED,
  IMAGE_SELECTED,
  HTTP_ERROR_ENCOUNTERED,
  OFFERS_UPLOADED,
  OFFER_DELETED,
  OTHER_FILES_UPLOADED,
  OTHER_FILE_DELETED,
  REGISTRATION_CERTIFICATE_DELETED,
  REGISTRATION_CERTIFICATE_UPLOADED,
  SAF_DOCUMENTS_UPLOADED,
  SAF_DOCUMENT_DELETED,
  OFFICIAL_REPORT_UPLOADED,
  OFFICIAL_REPORT_DELETED,
} from '@domain/action-types';

//setOfFiles can be:  'damageImages' | 'intakeImages' | 'additionalDamageImages' | 'otherFiles' | 'offers' | 'safDocuments' | 'greenCard' | 'registrationCertificate'
const useFilesEndpoint = (setOfFiles) => {
  const [numOfUploads, setNumOfUploads] = useState(0);
  const dispatch = useDispatch();
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)
  const [nonImageUpload, setNonImageUpload] = useState(false);
  const [nonAcceptedFileType, setNonAcceptedFileType] = useState(false)
  const [bigFileUpload, setBigFileUpload] = useState(false);
  const [largeFileUpload, setLargeFileUpload] = useState(false);

  const filesDeleting = useRef([]);
  const token = useSelector(state => state.token);
  const location = useLocation()

  const isFillingFeedback = checkIsFeedback(incident) && (location.pathname.includes('onboarding'))
  const FeedbackProcess = isFillingFeedback ? { FeedbackProcess: true } : {}
  const completeIncident = { ...incident, ...FeedbackProcess }

  const uploadImages = useCallback(
    async function uploadImages(uploads, maxImgSize, extraDamageImage, imageCategory) {
      try {
        const [actualImages, nonImagePresent] = filterNonImages(uploads);
        const [actualUploads, bigImagePresent, largeImagePresent] = filterLargeFiles(
          actualImages, maxImgSize
        );
        const numOfUploads = Object.keys(actualUploads).length;
        setNonImageUpload(nonImagePresent);
        setLargeFileUpload(largeImagePresent);
        setBigFileUpload(bigImagePresent);
        setNumOfUploads(num => num + numOfUploads);
        const uploadedImages = completeIncident && completeIncident.status ? await FilesService.postFiles(
          setOfFiles,
          actualUploads,
          completeIncident,
          token,
          insurer,
          extraDamageImage
        ) : await prepareFilesForUploading(actualUploads, insurer, extraDamageImage, imageCategory)

        setNumOfUploads(num => num - numOfUploads);
        if (setOfFiles === 'intakeImages') {
          dispatch({ type: INTAKE_IMAGES_UPLOADED, intakeImages: uploadedImages });
        }
        if (setOfFiles === 'damageImages') {
          dispatch({ type: IMAGES_UPLOADED, files: uploadedImages });
        }
      } catch ({ error }) {
        const errorLogMessage = `Error occurred inside usefilesEndpoint during post of images - ${setOfFiles} (FilesService.postFiles):`
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        dispatch({ type: HTTP_ERROR_ENCOUNTERED, error });
      }
    },
    [dispatch, setOfFiles, completeIncident, insurer, token],
  );

  const uploadFiles = useCallback(
    async function uploadFiles(uploads) {
      try {
        const [acceptedFiles, notAcceptedFileType] = filterFileTypes(uploads, ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']);
        const [actualUploads, bigFilePresent, largeFilePresent] = filterLargeFiles(
          acceptedFiles,
        );
        setNonAcceptedFileType(notAcceptedFileType)
        setLargeFileUpload(largeFilePresent);
        setBigFileUpload(bigFilePresent);
        const numOfUploads = Object.keys(actualUploads).length;
        setNumOfUploads(num => num + numOfUploads);
        const uploadedFiles = completeIncident && completeIncident.status ? await FilesService.postFiles(
          setOfFiles,
          actualUploads,
          completeIncident,
          token,
          insurer
        ) : await prepareFilesForUploading(actualUploads, insurer)

        setNumOfUploads(num => num - numOfUploads);
        if (setOfFiles === 'otherFiles') {
          dispatch({ type: OTHER_FILES_UPLOADED, files: uploadedFiles });
        }
        if (setOfFiles === 'safDocuments') {
          dispatch({ type: SAF_DOCUMENTS_UPLOADED, files: uploadedFiles });
        }
        if (setOfFiles === 'offers') {
          dispatch({ type: OFFERS_UPLOADED, files: uploadedFiles });
        }
        if (setOfFiles === 'greenCard') {
          dispatch({ type: GREENCARD_UPLOADED, files: uploadedFiles });
        }
        if (setOfFiles === 'registrationCertificate') {
          dispatch({ type: REGISTRATION_CERTIFICATE_UPLOADED, files: uploadedFiles })
        }
        if (setOfFiles === 'officialReports') {
          dispatch({ type: OFFICIAL_REPORT_UPLOADED, files: uploadedFiles })
        }
      } catch ({ error }) {
        const errorLogMessage = `Error occurred inside usefilesEndpoint during post of files - ${setOfFiles} (FilesService.postFiles):`
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        dispatch({ type: HTTP_ERROR_ENCOUNTERED, error });
      }
    },
    [dispatch, setOfFiles, completeIncident, insurer, token],
  );

  const deleteFile = async image => {
    const { cpID } = image;
    if (filesDeleting.current.includes(cpID)) {
      return;
    }
    filesDeleting.current.push(cpID);

    try {
      const res = await FilesService.deleteFile(setOfFiles, cpID, completeIncident, token);
      if (res[setOfFiles][0].status === 'Success') {
        if (setOfFiles === 'intakeImages') {
          dispatch({ type: INTAKE_IMAGE_DELETED, cpID });
        }
        if (setOfFiles === 'otherFiles') {
          dispatch({ type: OTHER_FILE_DELETED, cpID });
        }
        if (setOfFiles === 'safDocuments') {
          dispatch({ type: SAF_DOCUMENT_DELETED, cpID });
        }
        if (setOfFiles === 'damageImages') {
          dispatch({ type: IMAGE_DELETED, cpID });
        }
        if (setOfFiles === 'offers') {
          dispatch({ type: OFFER_DELETED, cpID });
        }
        if (setOfFiles === 'greenCard') {
          dispatch({ type: GREENCARD_DELETED, cpID });
        }
        if (setOfFiles === 'registrationCertificate') {
          dispatch({ type: REGISTRATION_CERTIFICATE_DELETED, cpID })
        }
        if (setOfFiles === 'officialReports') {
          dispatch({ type: OFFICIAL_REPORT_DELETED, cpID })
        }
      }
    } catch ({ error }) {
      const errorLogMessage = `Error occurred inside usefilesEndpoint during delete of files - ${setOfFiles} (FilesService.deleteFile):`
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch({ type: HTTP_ERROR_ENCOUNTERED, error });
    }
    filesDeleting.current = filesDeleting.current.filter(id => id !== cpID);
  };

  const setSelectedImage = useCallback(
    index => dispatch({ type: IMAGE_SELECTED, index }),
    [dispatch],
  );

  return {
    uploadFiles,
    uploadImages,
    deleteFile,
    bigFileUpload,
    nonAcceptedFileType,
    numOfUploads,
    nonImageUpload,
    largeFileUpload,
    setSelectedImage,
  };
};

export default useFilesEndpoint;
