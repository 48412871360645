// NPM imports.
import { datadogLogs } from '@datadog/browser-logs'

import React, { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { styled, useTheme } from '@mui/material/styles';

import {
  BackgroundVideo,
  Button,
  CompanyLogo,
  InputDialog,
  Container,
  FAQ,
  HeaderGroup,
  Heading,
  IconButton,
  Loader,
  Main,
  NavBar,
  Section,
  Text,
  Timeline,
  TimelineIcon,
  TimelineItem,
  UseIcon,
  ConfirmationDialog,
} from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { base64PdfToBlob, browserStorage, chooseLogoSize, chooseLogoToBeDisplayed, generateCalendarLink, generateEventForAddToCalendar } from '@domain/helpers';
import {
  useFaq,
  useThemes,
  useFormHandle,
  usePdfDossierEndpoint,
  useScaling,
  useSaveCallbackRequest,
  useSchadegarantBodyshopChange,

} from '@domain/hooks';
import { Colors } from '@domain/theming';
import validationSchema from './validation-schema';
import { isIE } from 'react-device-detect';
import routes from '../routes';
import { Navigate, useLocation } from 'react-router-dom';
import { useQuestionnaire } from '@domain/providers';
import { timelineSteps } from './timelineSteps';

const faqs = [
];

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  section {
    display: flex;
    flex-direction: column;
    padding: 22px 40px 82px;
    > div {
    }
    .track-trace__section--inner {
      .track-trace__content--heading {
        display: flex;
        padding-top: 30px;
        flex-direction: column;
        .header-group {
          margin-bottom: 50px;
          h1 {
            margin-top: 5px;
          }
          h2 {
            margin-top: 5px;
            padding-bottom: 20px;
          }
        }
      }
      .track-trace__content--info {
        display: flex;
        flex-direction: column;
        .h4 {
          // margin-bottom: 15px;
        }
        .button {
          margin-top: 15px;
          width: 100%;
          &__contact {
            height: auto;
            text-align: left;
            padding: 24px;
            .button-wrapper {
              justify-content: space-between;
            }
            .header-group {
              margin-right: auto;
            }
            span {
              text-transform: initial;
              font-weight: normal;
            }
            .h5 {
              margin-bottom: 15px;
            }
          }
        }
      }
      .track-trace__timeline--container {
        display: flex;
        ul.timeline {
          padding: 50px 0;
          li {
            // flex-direction: column;
            .timeline__subtext {
              // padding-left: 15px;
              // font-weight: normal;
            }
            .timeline__text {
              // padding-left: 15px;
              // max-width: none;
              color: ${props => props.theme.isCustomThemed ? Colors.BLACK : Colors.WHITE};
            }
          }
          .timeline__item {
            // opacity: .3;
            &:before {
              // background-color: $white;
            }
            &:last-child {
              // padding-bottom: 20px;
            }
            &:not(.timeline__item--muted) {
              // opacity: 1;
            }
          }
          .timeline__item--muted {
            // color: $white;
            &:before {
              // background-color: $white;
            }
          }
        }
      }
    }
  }

  .screen-desktop & {
    section {
      align-items: center;
      flex-direction: row;
      padding: ${props => props.$scaling.scaleUpTo4K(30)}px ${props => props.$scaling.scaleUpTo4K(120)}px ${props => props.$scaling.scaleUpTo4K(74)}px;
      .track-trace__section--inner {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        align-self: stretch;
        .track-trace__content--heading {
          flex: 1 1 auto;
          width: 60%;
          justify-content: flex-end;
          padding-top: 0;
          .header-group {
            margin: ${props => props.$scaling.scaleUpTo4K(60)}px 0 ${props => props.$scaling.scaleUpTo4K(40)}px;
          }
        }
        .track-trace__content--info {
          flex: 1 1 auto;
          width:60%;
          justify-content: flex-start;
          > div {
            max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
          }
          .button {
            margin-top: ${props => props.$scaling.scaleUpTo4K(15)}px;
            &__contact {
              padding: ${props => props.$scaling.scaleUpTo4K(24)}px;
              .header-group {
              }
              .h5 {
                margin-bottom: ${props => props.$scaling.scaleUpTo4K(15)}px;
              }
            }
          }
        }
        .track-trace__timeline--container {
          align-items: center;
          justify-content: flex-end;
          position: absolute;
          right: 0;
          top: 0;
          width: 40%;
          min-height: calc(100vh - ${props => props.$scaling.scaleUpTo4K(186)}px);
          padding-right: ${props => props.$scaling.scaleUpTo4K(40)}px;
          padding-bottom: ${props => props.$scaling.scaleUpTo4K(50)}px;
          ul.timeline {
            max-width: ${props => props.$scaling.scaleUpTo4K(400)}px
            padding: ${props => props.$scaling.scaleUpTo4K(100)}px 0 0;
            li {
              .timeline__subtext {
                color: ${props => props.theme.isCustomThemed ? Colors.BLACK : Colors.WHITE};
              }
              .timeline__text {
                color: ${props => props.theme.isCustomThemed ? Colors.BLACK : Colors.WHITE};
                min-height: unset;
              }
            }
          }
        }
      }
    }
  }

  .screen-ie & {
    .track-trace__content--info {
      .button {
        max-height: none;
      }
    }
  }

  .theme-custom & {
    .track-trace__content--heading {
      color: ${props => props.theme.isCustomThemed ? Colors.BLACK : Colors.WHITE};
    }
    .h4 {
      color: ${props => props.theme.isCustomThemed ? props.theme.colors.primary : Colors.WHITE};
    }
    .track-trace__timeline--container {
      .timeline-checkmark {
        background: ${props => props.theme.isCustomThemed ? Colors.GREEN : props.theme.colors.primary};
      }
      .timeline__item {
        color: ${Colors.WHITE};
        &:before {
          background-color:  ${props => props.theme.isCustomThemed ? props.theme.colors.primary : Colors.WHITE};
        }
      }
      .timeline__item--muted {
        &:before {
          background-color:  ${props => props.theme.isCustomThemed ? props.theme.colors.primary : Colors.WHITE};
        }
        .timeline__icon {
        }
      }
    }
  }

  @media screen and (max-width: 1263px) {
    .screen-desktop & {
      section {
        padding: 30px 60px 74px;
      }
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      section {
        padding: 30px 40px 40px;
        .track-trace__section--inner {
          > div {
            max-width: 500px;
            width: 100%;
            height: auto;
            margin: 0 auto;
          }
          .track-trace__content--heading {
            max-width: 580px;
            width: 100%;
            text-align: center;
            .header-group {
              max-width: none;
              margin-bottom: 0;
              margin-top: 30px;
            }
          }
          .track-trace__content--info {
            width: 100%;
            text-align: center;
            > div {
              max-width: none;
            }
            .button {
              max-width: 580px;
              margin: 15px auto 0;
              &__contact {
                .header-group {
                }
              }
            }
          }
          .track-trace__timeline--container {
            flex: 1 0 auto;
            position: relative;
            flex-direction: column;
            padding: 0;
            width: 100%;
            min-height: auto;
            padding-bottom: 0;
            ul.timeline {
              padding-bottom: 80px;
              max-width: 580px;
            }
          }
        }
      }
    }
  }
`;

function TrackTrace() {

  const bodyshop = useSelector(state => state.bodyshop) || {};
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const location = useLocation()

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const downloadingPdfDossier = useRef(false);
  const { getPdfDossier } = usePdfDossierEndpoint();

  const { questionnaire } = useQuestionnaire();
  browserStorage.clearInBodyshopChange()

  const themes = useMemo(() => [isThemed ? 'theme-custom' : (incident && insurer && bodyshop) && 'theme-dark'], [bodyshop, incident, insurer, isThemed]);
  const themeContext = useTheme();
  const scaling = useScaling();
  const schadegarantBodyshopChange = useSchadegarantBodyshopChange();
  useThemes(themes);
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const [callbackDialogOpen, setCallbackDialogOpen] = useState(false);
  const [bodyshopChangeDialogOpen, setBodyshopChangeDialogOpen] = useState(false);
  const [bodyshopChangeBlockedDialogOpen, setBodyshopChangeBlockedDialogOpen] = useState(false);
  const [pdfDossierWebServiceFailedDialogOpen, setPdfDossierWebServiceFailedDialogOpen] = useState(false);


  //////////// To handle callback request.

  const { callbackConfirmationMessage,
    setCallbackConfirmationMessage,
    saveCallbackRequest } = useSaveCallbackRequest();



  const onValidated = useCallback(
    async function onValidated(values) {
      await saveCallbackRequest({ ...values, callbackRequestDate: moment(new Date()).locale('nl').format('YYYY-MM-DDTHH:mm:ss') })
    }, [saveCallbackRequest]);


  const form = {
    id: 'callback'
  };
  const field = {
    name: 'callbackReason',
    label: 'Reden voor terugbelverzoek',
  }

  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    buttonClicked,
  } = useFormHandle(
    validationSchema,
    { [field.name]: "" },
    onValidated,
  );

  const formInput = ({ name, label }) => ({
    type: 'text',
    name,
    id: name,
    valid: !errors[name] || !buttonClicked,
    onChange: handleChange,
    label,
    regexPass: !errors[name],
    value: values[name],
    displayLabel: false,
    customBaseSize: 24,
    transparentAdornment: true,
    multiline: true,
  });


  const handleOnClickButton = () => {
    const protocol = bodyshop.website.startsWith('http')
    if (protocol) {
      window.open(bodyshop.website, '_blank');
    } else {
      window.open(`http://${bodyshop.website}`, '_blank');
    }
  };


  const handleOnClickPdfDossierButton = async () => {
    if (downloadingPdfDossier.current) {
      return null;
    } else {
      downloadingPdfDossier.current = true;

      const downloadPdfDossier = (response) => {
        const pdfAsUrl = response.dossiers[0].content;
        const pdfFileName = response.dossiers[0].fileName || 'dossier.pdf';
        const blob = base64PdfToBlob(pdfAsUrl);
        if (isIE) {
          window.navigator.msSaveOrOpenBlob(blob, `${pdfFileName}`)
        } else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', `${pdfFileName}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      const storedPdfDossierResponse = browserStorage.getStoredPdfDossierResponse();
      if (storedPdfDossierResponse) {
        try {
          downloadPdfDossier(storedPdfDossierResponse);
        } catch (error) {
          const errorLogMessage = `error downloading pdf from stored response on local storage`
          const errorInstance = new Error(errorLogMessage)
          datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        }
        downloadingPdfDossier.current = false;

      } else {
        const response = await getPdfDossier();
        if (!!response && response.dossiers && response.dossiers.length > 0) {

          browserStorage.setStoredPdfDossierResponse(response);
          try {
            downloadPdfDossier(response);
          } catch (error) {
            const errorLogMessage = `error downloading pdf from ws response`
            const errorInstance = new Error(errorLogMessage)
            datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
          }
          downloadingPdfDossier.current = false;
        } else {
          setPdfDossierWebServiceFailedDialogOpen(true);
          downloadingPdfDossier.current = false;
        }
      }
    }
  }

  const handleOnClickButtonCallBack = () => {
    setCallbackConfirmationMessage(null)
    setCallbackDialogOpen(true);
  };

  const handleSubmitCallbackRequest = (event) => {
    event.preventDefault()
    handleSubmit(event)
  }

  const handleCloseCallbackDialog = () => {
    setCallbackDialogOpen(false)
  }


  let activeStep = 0;


  function initActiveStep(steps, status) {
    for (let i = 0; i <= steps.length - 1; i++) {
      if (status && steps[i].status.indexOf(status) !== -1) {
        activeStep = i;
      } else {
      }
    };
  }

  const [allowBodyshopChange, setAllowBodyshopChange] = useState(false)

  useEffect(() => {

    if (incident == null || !incident.bodyshopChangeAllowDate || allowBodyshopChange) return
    const bodyshopChangeAllowDate = moment.utc(incident.bodyshopChangeAllowDate).locale('nl')

    const now = moment().utc().locale('nl')
    const timspanToAllowBodyshop = bodyshopChangeAllowDate.diff(now)
    const shouldAllowBodyshopChange = timspanToAllowBodyshop <= 0

    if (shouldAllowBodyshopChange) {
      setAllowBodyshopChange(true)
    } else {
      const timeout = setTimeout(() => {
        setAllowBodyshopChange(true)
      }, timspanToAllowBodyshop)

      return () => clearTimeout(timeout)
    }

  }, [allowBodyshopChange, setAllowBodyshopChange, incident])


  if (!incident || !insurer || !Object.keys(bodyshop).length || !questionnaire || !questionnaire.length) {
    return <Loader />;
  }

  const {
    customerFirstName,
    customerLastName,
    intakeDate,
    intakeDate_EndWindow,
  } = incident;
  const timeFormat = 'HH:mm';
  const dateFormat = 'dddd D MMM';
  const checkinDate = moment.utc(intakeDate).locale('nl').format(dateFormat);
  const checkinTime = moment.utc(intakeDate).locale('nl').format(timeFormat);
  const checkinTimeEndWindow = moment.utc(intakeDate_EndWindow).locale('nl').format(timeFormat);
  const failedCallDate = incident.failedCallDate;
  const failedCallTime = incident.failedCallTime;


  const checkoutDateToConsider = moment.utc(incident.outtakeDate).locale('nl')
  const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
  const formattednow = moment().utc(now).locale('nl')

  const shouldShowCaseClosed = checkoutDateToConsider.diff(formattednow) <= 0
  const status = (incident && incident.status === 'damage_closed' && !shouldShowCaseClosed) ? 'future_outtake_scheduled' : incident.status

  const checkForOutlet = () => {
    const bs = incident && incident.outletUUID && bodyshop && bodyshop.outlets ? bodyshop.outlets.find(outlet => outlet.uuid === incident.outletUUID) : {}
    if (!!bs) {
      return { ...bodyshop, ...bs }
    }
    return bodyshop
  }

  const { objectType } = insurer
  const isVIP = (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand))

  const finalBodyshop = checkForOutlet()

  const bodyshopFound = !!bodyshop && Object.keys(bodyshop).length !== 0
  const bodyshopName = bodyshopFound ? finalBodyshop.name || finalBodyshop.companyName : '';

  const hubChauffeurs = bodyshopFound ? bodyshop.chauffeur : {};
  const chauffeur =
    hubChauffeurs && hubChauffeurs.length > 0
      ? hubChauffeurs[hubChauffeurs.length - 1]
      : { name: 'De chauffeur' };

  const expertiseRequested = incident.expertiseStatus && incident.expertiseStatus === "Requested"
  const expertiseDeclined = incident.expertiseStatus && incident.expertiseStatus === "Declined"
  const expertiseApproved = incident.expertiseStatus && incident.expertiseStatus === "Approved"

  const expertiseTimeline = [{
    name: 'Voorexpertise aangevraagd',
    icon: 'search',
    text: 'De verzekeringsexpert geeft aan dat er niet gestart moet worden met het herstellen van uw auto.',
    status: [
      'damage_cancelled'
    ]
  }]

  const availableSteps = timelineSteps(incident, bodyshop, bodyshopName, objectType, isVIP, status, chauffeur)

  const glassTimeline = [
    availableSteps.firstStep,
    availableSteps.thirdStep,
    availableSteps.sixthStep
  ]

  const steps = expertiseDeclined
    ? expertiseTimeline
    : objectType === companyNames.Schadegarant && incident.damageType === 'Glas'
      ? glassTimeline
      : [
        ...(expertiseApproved ? [{
          name: 'Voorexpertise aangevraagd',
          icon: 'search',
          text: 'De verzekeringsexpert geeft aan dat er gestart kan worden met herstel.',
          status: [],
        }] : []),
        availableSteps.firstStep,
        availableSteps.secondStep,
        availableSteps.thirdStep,
        availableSteps.fourthStep,
        availableSteps.fifthStep,
        availableSteps.sixthStep,
      ]

  initActiveStep(steps, status);

  const companyLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: isVIP ? 'vip-logo' : '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  };

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const button = {
    onClick: handleOnClickButton,
    className: 'button__contact',
    theme: 'secondary',
    justify: 'space-between',
    disabled: !bodyshop.website
  };

  const buttonBodyshopChange = {
    onClick: allowBodyshopChange ? () => setBodyshopChangeDialogOpen(true) : () => setBodyshopChangeBlockedDialogOpen(true),
    className: 'button__bodyshop-change',
    theme: 'primary',
    // disabled: false,
  }

  const buttonDownloadPDF = {
    onClick: handleOnClickPdfDossierButton,
    className: 'button__download-pdf',
    // disabled: !!pdfDossier,
    theme: 'primary',
  }

  const buttonCallBack = {
    onClick: handleOnClickButtonCallBack,
    className: 'button__callback',
    theme: 'primary',
    disabled: incident && incident.callbackRequestPossible === false
  };
  const callBackButtonContent = buttonCallBack.disabled ?
    <span className="text">U heeft aangegeven teruggebeld te willen worden. U kunt natuurlijk ook zelf naar ons bellen op {bodyshop.phoneNumber}</span> : 'Terugbelverzoek'

  const main = {
    className: 'main__track-trace',
    callBack: clickOutSideFaq,
    dimmed: faqVisible,
    faqVisible: faqVisible,
    $scaling: scaling,
  };

  const navbar = (
    <NavBar isVIP={isVIP}>
      <Container className="empty-div" />
      {objectType === proccessType.Schadegarant ? <CompanyLogo {...middleLogo} /> : <CompanyLogo {...companyLogo} />}
      {faqs.length > 0 ?
        <Container className="faq-button-container">
          <IconButton
            type={faqVisible ? 'arrow-right' : 'questionmark'}
            className={faqSeen ? '' : 'unclicked-faq-button'}
            theme="secondary"
            onClick={handleOnHelpClick}
            faq={true}
            size="large" />
        </Container> :
        <Container className="empty-div" />
      }
    </NavBar>
  );


  const checkin = {
    date: checkinDate,
    time: checkinTime,
    timeEnd: checkinTimeEndWindow,
  }
  const nextStepWidgetActive = false;
  const nextStepWidget = (
    <>
      {nextStepWidgetActive &&
        <Container className="track-trace__content--next-step">
          <Heading level="4">{steps[activeStep].name}</Heading>
          <Heading level="1">{checkin.date}</Heading>
          <Heading level="1">{checkin.time + ' - ' + checkin.timeEnd}</Heading>
        </Container>}
    </>
  )

  const innerContent = ((!!insurer && insurer.objectType === companyNames.Schadegarant) || (!!insurer && insurer.objectType === proccessType.DealerSass))
    ? <>
      {customerFirstName && customerLastName
        ? <Heading level="2">
          {'Beste ' + customerFirstName + ' ' + customerLastName + ','}
        </Heading>
        : <Heading level="2">
          Beste
        </Heading>
      }
    </> :
    <>
      {customerFirstName && customerLastName
        ? <Heading level="2">
          {'Beste, ' + customerFirstName + ' ' + customerLastName}
        </Heading>
        : <Heading level="2">
          Beste,
        </Heading>
      }
    </>

  const heading = (
    <Container className="track-trace__content--heading">
      <HeaderGroup>
        {innerContent}
        {objectType === companyNames.Schadegarant
          ? status === 'damage_cancelled' && expertiseDeclined
            ? <><Text>De verzekeringsexpert geeft aan dat er niet gestart moet worden met het herstellen van uw auto.</Text> <Text> De verzekeringsexpert bespreekt dit met u.</Text></>
            : status === 'damage_cancelled'
              ? <Text>Neem contact op met uw verzekeraar voor het afhandelen van het herstel.</Text>
              : expertiseRequested
                ? <> <Text>De schade aan uw auto zal bekeken worden door de verzekeringsexpert.</Text><Text>De verzekeringsexpert zal bekijken of de schade aan uw voertuig hersteld zal worden.</Text><Text> Zodra de verzekeringsexpert ons dit heeft laten weten krijgt u een nieuwe update.</Text></>
                : status === 'carrefinisher_no_contact'
                  ? <Text>Wij hebben u gebeld {failedCallDate && failedCallTime && 'op ' + failedCallDate + ' ' + failedCallTime + ' gebeld'} voor een herstelafspraak, maar konden u helaas niet bereiken. Wij zullen nogmaals contact met u proberen op te nemen. <br />U kunt ook zelf bellen om een afspraak te maken.</Text>
                  : <Text>Hier vindt u een overzicht van het gehele proces en al uw informatie.</Text>
          : status === 'damage_cancelled'
            ? objectType === proccessType.DealerSass
              ? <><Text>Wij hebben dit schadedossier afgebroken. Dit kan door diverse redenen ontstaan zoals bijvoorbeeld doordat het voertuig niet afdoende verzekerd is, de premies niet tijdig betaald zijn waardoor de dekking opgeschort is of als de polis inmiddels beëindigd is.</Text><Text> U kunt contact met ons opnemen met onze klantenservice om de schade te bespreken.</Text></>
              : <Text>Dit schadedossier is afgebroken. Neem contact op met de klantenservice als dit foutief is</Text>
            : objectType === proccessType.DealerSass
              ? <Text>Hieronder vindt u een overzicht van het gehele proces en al uw informatie.</Text>
              : <Text>Hier vindt u een overzicht van het gehele proces en al uw informatie.</Text>}
      </HeaderGroup>
      {nextStepWidget}
    </Container>
  );

  const calendarEvents = generateEventForAddToCalendar(incident, finalBodyshop, bodyshopName)
  const checkinEvent = calendarEvents.checkinEvent
  const checkoutEvent = calendarEvents.checkoutEvent
  const activeStepIsBeforeCheckin = steps.findIndex(step => step.status.find(status => status === 'date_planned')) >= activeStep

  const showCheckinButton = checkinEvent && activeStepIsBeforeCheckin
  const caseClosed = (status === 'Car_Collected' || status === 'damage_closed' || status === 'payment' || status === 'Review_Recieved' || status === 'future_outtake_scheduled')

  const info = (
    <Container className="track-trace__content--info">
      <Container>
        {status !== 'damage_cancelled' &&
          <>
            <Heading level="4">Informatie</Heading>
            <Button {...button}>
              <HeaderGroup>
                <Heading level="5">{bodyshopName}</Heading>
                <Text>{
                  finalBodyshop.street + ' ' +
                  finalBodyshop.houseNumber + ','
                }<br />{
                    finalBodyshop.zipcode + ' ' +
                    finalBodyshop.city
                  }</Text>
              </HeaderGroup>
              {!button.disabled && <UseIcon name="arrow-right" className="button__icon" />}
            </Button>
            {insurer.objectType === companyNames.Schadegarant && (incident.status === '_new' || incident.status === 'carrefinisher_choosen') && <Button {...buttonBodyshopChange}>Ik wil toch een ander herstelbedrijf selecteren</Button>}
            {insurer.objectType === companyNames.Schadegarant && incident.status === 'damage_closed' && <Button {...buttonDownloadPDF}>PDF Dossier Downloaden</Button>}
            {!caseClosed && <Button {...buttonCallBack}><span>{callBackButtonContent}</span></Button>}
            {showCheckinButton && <Button onClick={() => generateCalendarLink(checkinEvent)}>Check-in Toevoegen aan agenda</Button>}
            {checkoutEvent && <Button onClick={() => generateCalendarLink(checkoutEvent)}>Check-out toevoegen aan agenda</Button>}
          </>
        }
      </Container>
    </Container>
  );
  const timeline = (
    <Container className="track-trace__timeline--container">
      <Timeline>

        {steps.map((item, i) => {
          const { icon, name, subText, text } = item;

          return (
            <React.Fragment key={name}>
              <TimelineItem active={i === activeStep} muted={i > activeStep}>
                <TimelineIcon name={icon} className="timeline-icon" muted={i > activeStep} />
                {i < activeStep &&
                  <UseIcon
                    name="checkmark"
                    className="timeline-checkmark"
                    width="19"
                    height="19"
                  />
                }
                <Text className="timeline__text">
                  {text}
                </Text>
                {i === activeStep &&
                  <Text className="timeline__subtext">
                    {subText}
                  </Text>
                }
              </TimelineItem>
            </React.Fragment>
          )
        })}
      </Timeline>

    </Container>
  );

  const currentPath = location.pathname;
  const { onboarding } = routes;
  const page = questionnaire.find(page => page.path === currentPath)

  if (!page) {
    return <Navigate to={onboarding} />
  }
  return (
    <React.Fragment>
      <InputDialog
        content={callbackConfirmationMessage}
        onYesClick={handleSubmitCallbackRequest}
        onNoClick={() => (setCallbackDialogOpen(false))}
        onCloseClick={handleCloseCallbackDialog}
        open={callbackDialogOpen}
        title="Wilt u een terugbelverzoek indienen?"
        yesLabel="Bevestigen"
        noLabel="Annuleren"
        form={form}
        formInput={formInput(field)}
        errors={errors}
        buttonClicked={buttonClicked}
        confirmationMessage={callbackConfirmationMessage}
      />
      <ConfirmationDialog
        content="Ik wil toch een ander herstelbedrijf selecteren."
        onYesClick={schadegarantBodyshopChange}
        onNoClick={() => (setBodyshopChangeDialogOpen(false))}
        open={bodyshopChangeDialogOpen}
        yesLabel="Bevestigen"
        noLabel="Annuleren"
        title=""
      />
      <ConfirmationDialog
        content="Over 24 uur kunt u het eerder gekozen herstelbedrijf pas wijzigen in een ander bedrijf. Dit voorkomt dat uw dossier op twee plaatsen tegelijkertijd in behandeling wordt genomen."
        onYesClick={() => setBodyshopChangeBlockedDialogOpen(false)}
        open={bodyshopChangeBlockedDialogOpen}
        yesLabel="Bevestigen"
        title=""
        showNoButton={false}
      />
      <ConfirmationDialog
        content="Op het moment lukt het niet om uw dossier op te halen. Probeer het later nog een keer."
        onYesClick={() => (setPdfDossierWebServiceFailedDialogOpen(false))}
        open={pdfDossierWebServiceFailedDialogOpen}
        title="PDF Dosssier downloaden"
        showNoButton={false}
        yesLabel="Bevestigen"
      />
      {faqs.length > 0 &&
        <FAQ
          faqListProps={faqListProps}
          faqs={faqs}
          faqLink={insurer.helpcenterLink}
          faqVisible={faqVisible}
        />}
      <StyledMain {...main}>
        {navbar}
        <Section>
          <Container className="track-trace__section--inner">
            {heading}
            {status !== 'damage_cancelled' && timeline}
            {(status === 'damage_cancelled' && expertiseDeclined) && timeline}
            {info}
          </Container>
        </Section>
      </StyledMain>
      {objectType !== companyNames.Schadegarant && <BackgroundVideo
        type="video/mp4"
        loop={true}
        autoPlay={true}
        videoFileName="DEF2.mp4"
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
        faqVisible={faqVisible}
      />}
    </React.Fragment>
  );
}


export default TrackTrace;
