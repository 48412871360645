// NPM imports.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'es6-promise/auto';
// Only polyfill related imports before this line
import React from 'react';
import { createRoot } from 'react-dom/client'
import { BrowserView, TabletView, MobileOnlyView } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async'
import store from './store';

import routes from './routes';

import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs';

// Initialize yup localization and custom validators.
import '@domain/yup';

// Component imports.
import App from './App';
import Preview from './Preview';
import FallbackComponent from './Error';
import * as serviceWorker from './serviceWorker';

import { detectBrowsers } from '@domain/helpers';


// Initialize Datadog RUM datacapture (user logs)
datadogRum.init({
  applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
  env: process.env.REACT_APP_DD_ENV,
  sessionSampleRate: 100,
  useSecureSessionCookie: true,
  sessionReplaySampleRate: 0,
  trackLongTasks: true,
  trackResources: true,
  service: 'Customer Portal RUM',
  site: 'datadoghq.com',
  // trackInteractions: true
});
datadogLogs.init({
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN_LOGS,
  env: process.env.REACT_APP_DD_ENV,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  useSecureSessionCookie: true,
  service: 'Customer Portal browser logs',
  site: 'datadoghq.com',
});

function MainApp() {
  return (
    /*  This provider is what allows the app to use the Redux store It looks similar to a Context provider,
        but it contains some under-the-hood logic that prevents unnecessary rerendering.
        This is one way in which Redux is superior to Context for larger apps */
    <Provider store={store}>
      <HttpsRedirect disabled={!!window.location.hostname.endsWith('localhost.com') || !!process.env.REACT_APP_LOCAL}>
        {/* The ErrorBoundary component essentially wraps the app in a massive try-catch block
            If the app catches, because it tried to access a property on null for instance, then it will display the FallbackComponent
            Note that on a development server you will only see the FallbackComponent briefly before React's standard error page appears
            This is good because React's error page will actually tell you what went wrong */}
        <ErrorBoundary FallbackComponent={FallbackComponent}>
          <BrowserRouter>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </HttpsRedirect>
    </Provider>
  );
}

function renderMainframe() {
  const isPreview = window.location.pathname.includes(routes.preview);

  if (isPreview) {
    return (
      <>
        <Provider store={store}>
          <BrowserRouter>
            <Preview />
          </BrowserRouter>
        </Provider>
      </>
    );
  } else {
    return (<>
      <MobileOnlyView className="screen-mobile">
        <MainApp />
      </MobileOnlyView>
      <TabletView className={'screen-tablet ' + detectBrowsers()}>
        <MainApp />
      </TabletView>
      <BrowserView className={detectBrowsers()}>
        <MainApp />
      </BrowserView>
    </>
    );
  }


}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  renderMainframe(),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
