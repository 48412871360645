import React from 'react';

import { Colors } from '@domain/theming';
import { styled } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import UseIcon from '../UseIcon';
import { useScaling } from '@domain/hooks';

const StyledFileIcon = styled(UseIcon, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  background: ${Colors.BLACK};
  color: ${Colors.WHITE};
  border: 1px solid ${Colors.WHITE};
  border-radius: 35%;
  .screen-desktop & {
    width: ${props => props.$scaling.scaleUpTo4K(24)}px;
    height: ${props => props.$scaling.scaleUpTo4K(24)}px;
    margin-right: ${props => props.$scaling.scaleUpTo4K(8)}px;
  }
`;

const StyledMinusIcon = styled(UseIcon, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  color: ${Colors.BLACK};
  .screen-desktop & {
    width: ${props => props.$scaling.scaleUpTo4K(24)}px;
    height: ${props => props.$scaling.scaleUpTo4K(24)}px;
  }
`;

const StyledDiv = styled('div')(({ theme }) => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: '100px',
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

function AttachmentViewer({ files, onClickDelete }) {
  const scaling = useScaling();

  function generateItems() {
    const filesToDisplay = files ? files : []
    if (filesToDisplay.length > 0) {
      const fileItems = files.map((file) => {
        return (
          <ListItem key={file.cpID} style={{ backgroundColor: '#ffffff', paddingTop: '5px', paddingBottom: '5px', borderRadius: '8px', marginTop: '5px', marginBottom: '5px' }}>
            <ListItemIcon  >
              <StyledFileIcon
                name="icon-file"
                className="image-uploads"
                $scaling={scaling}
              />
            </ListItemIcon>
            <ListItemText
              primary={file.fileName}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onClickDelete(file)}
                size="large">
                <StyledMinusIcon
                  name="icon-minus-circle"
                  className="image-minus"
                  $scaling={scaling}
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })
      return fileItems
    } else {
      return null
    }
  }

  return (
    <>
      <StyledDiv >
        <div >
          <List>
            {generateItems()}
          </List>
        </div>
      </StyledDiv>
    </>
  )
}

export default AttachmentViewer
