
import React, { useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
  nlNL
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import moment from 'moment';
import 'moment/locale/nl';
import { isMobile } from 'react-device-detect';
import { useScaling } from '@domain/hooks';
import { nl } from 'date-fns/locale'
const PREFIX = 'DatePicker';

const classes = {
  container: `${PREFIX}-container`,
  textField: `${PREFIX}-textField`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  adornmentRoot: `${PREFIX}-adornmentRoot`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelShrink: `${PREFIX}-inputLabelShrink`,
  inputError: `${PREFIX}-inputError`,
  inputLabelAsterisk: `${PREFIX}-inputLabelAsterisk`,
  inputLabelFormControl: `${PREFIX}-inputLabelFormControl`,
  inputUnderline: `${PREFIX}-inputUnderline`,
  inputLabelClasses: `${PREFIX}-inputLabelClasses`,
  inputDisabled: `${PREFIX}-inputDisabled`,
  inputLabelDisabled: `${PREFIX}-inputLabelDisabled`,
  focused: `${PREFIX}-focused`,
  inputLabelError: `${PREFIX}-inputLabelError`,
  dense: `${PREFIX}-dense`,
  menu: `${PREFIX}-menu`
};

const StyledMuiDatePicker = styled(MuiDatePicker, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})((
  {
    theme, $scaling, defaultValue, value, inputRef, $color, $colorDanger, $black, valid
  }
) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  marginLeft: theme.spacing(0),
  marginRight: theme.spacing(1),
  width: '100%',
  // height: '67px',
  height:
    (isMobile
      ? $scaling.textSizeMobile(67)
      : $scaling.textSize(67)) + 'px',
  // background: 'rgba(30,0,255,0.2)'

  [`& .${classes.inputRoot}`]: {
    fontSize:
      (isMobile
        ? $scaling.inputSizeMobile(defaultValue || value, 32, inputRef.current)
        : $scaling.inputSize(defaultValue || value, 32, inputRef.current)) + 'px',
    fontWeight: 'bold',
    lineHeight:
      (isMobile
        ? $scaling.textSizeMobile(36)
        : $scaling.textSize(36)) + 'px',
    borderColor: $color,
    // alignItems: 'flex-end',
    '&$inputDisabled': {
      color: black,
    },
    // height: '51px',
    marginBottom: '0',
    marginTop: 'auto',
    // background: 'rgba(30,0,255,0.2)'
  },

  [`& .${classes.inputInput}`]: {
    textAlign: 'left',
    letterSpacing: 'normal',
    marginTop: 'auto',
    paddingTop:
      ((!isMobile ?
        $scaling.textSize(6) :
        $scaling.textSizeMobile(6)) + 'px'),
    paddingBottom:
      ((!isMobile ?
        $scaling.textSize(7) :
        $scaling.textSizeMobile(7)) + 'px'),
    paddingLeft: '0',
    height: isMobile
      ? $scaling.textSizeMobile(56)
      : $scaling.textSize(56) + 'px',
    // background: 'rgba(30,0,255,0.2)'
    // marginBottom: '8px'
  },

  [`& .${classes.adornmentRoot}`]: {
    height:
      (isMobile
        ? $scaling.textSizeMobile(56)
        : $scaling.textSize(56)) + 'px',
    '& button': {
      '.theme-custom &': {
        color: valid ? theme.colors.primary : $colorDanger,
      }
    },
    '& svg': {
      fontSize: ((!isMobile ?
        $scaling.textSize(24) :
        $scaling.textSizeMobile(24)) + 'px'),
    }
  },

  [`& .${classes.inputLabelRoot}`]: {
    display: 'block',
    fontFamily: theme.fontFamily,
    height: $scaling.scaleUpTo4K(44) + 'px',
    borderBottom: '0.5px',
    color: $color,
    fontSize:
      (isMobile
        ? $scaling.inputSizeMobile(defaultValue || value, 32, inputRef.current)
        : $scaling.inputSize(defaultValue || value, 32, inputRef.current)) + 'px',
    fontWeight: 'bold',
    lineHeight: (isMobile
      ? $scaling.textSizeMobile(32)
      : $scaling.textSize(32)) + 'px',
    transform: `translate(0, ${$scaling.scaleUpTo4K(25)}px)`,
    whiteSpace: 'nowrap',
    '&$inputLabelDisabled': {
      color: $color,
    },
    '&+$inputRoot': {
      marginTop: '0',
    },
    '.theme-custom &': {
      color: theme.colors.primary,
      // fontFamily: 'Dag'
    }
  },

  [`& .${classes.inputLabelShrink}`]: {
    fontSize:
      (isMobile
        ? $scaling.inputSizeMobile(defaultValue || value, 16, inputRef.current)
        : $scaling.inputSize(defaultValue || value, 16, inputRef.current)) + 'px',
    fontWeight: 'bold',
    color: $color,
    transform: `translate(0, -${((!isMobile ?
      $scaling.textSize(20) :
      $scaling.textSizeMobile(20)))}px)`,
    lineHeight: '14px',
    letterSpacing: '0.0625em',
    transformOrigin: 'top left',
    '.theme-custom &': {
      color: theme.colors.primary,
    }
  },

  [`& .${classes.inputError}`]: { color: $colorDanger },

  [`& .${classes.inputLabelAsterisk}`]: {
    visibility: 'hidden',
  },

  [`& .${classes.inputLabelFormControl}`]: {
    '&$focused': {
      color: $color,
      fontWeight: 'bold',
    },
    '&$inputLabelError': {
      color: $colorDanger,
    },
    '.theme-custom &': {
      '&$focused': {
        color: theme.colors.primary,
      }
    }
  },

  [`& .${classes.inputUnderline}`]: {
    '&$inputError': {
      '&:before': {
        borderColor: $colorDanger,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${$colorDanger}`,
      },
      '&:after': {
        borderBottom: `2px solid ${$colorDanger}`,
      },
    },
    '&:before': {
      borderColor: $color,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${$color}`,
    },
    '&:after': {
      borderBottom: `2px solid ${$color}`,
    },
    '&$inputDisabled:before': {
      borderBottom: '0px',
    },
    '&$inputDisabled:after': {
      borderBottom: '0px',
    },
    '.theme-custom &': {
      '&:before': {
        borderColor: theme.colors.primary,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${theme.colors.primary}`
      },
      '&:after': {
        borderBottom: `2px solid ${theme.colors.primary}`
      },
    },
  },

  [`& .${classes.inputLabelClasses}`]: {},
  [`& .${classes.inputDisabled}`]: {},
  [`& .${classes.inputLabelDisabled}`]: {},
  [`& .${classes.focused}`]: {},
  [`& .${classes.inputLabelError}`]: {},

  [`& .${classes.dense}`]: {
    marginTop: 19,
  },

  [`& .${classes.menu}`]: {
    width: 200,
  }
}));

const colorDanger = '#ed1c24';
const black = '#000000';

function DatePicker({ name, onChange, valid, value, disabled, openTo }) {
  const hasDefaulted = useRef(false);
  const inputRef = useRef(null);
  const defaultValue = value || null;
  const scaling = useScaling();
  const handleChange = useCallback(
    function handleChange(date) {
      const dateFormat = 'YYYY-MM-DD';
      const formattedDate = moment(date).format(dateFormat);

      const event = {
        target: {
          name,
          value: formattedDate,
        },
      };
      onChange(event);
    },
    [name, onChange],
  );

  const color = valid ? black : colorDanger;

  useEffect(() => {
    if (hasDefaulted.current) {
      return;
    }

    hasDefaulted.current = true;

    if (value) {
      return;
    }

    handleChange(null);
  }, [handleChange, value]);

  const nlLocale = nlNL.components.MuiLocalizationProvider.defaultProps.localeText;
  const customLocaleText = {
    fieldYearPlaceholder: () => 'jjjj',
    fieldMonthPlaceholder: () => 'mm',
    fieldDayPlaceholder: () => 'dd',
  }

  return (
    <LocalizationProvider
      adapterLocale={nl}
      localeText={{ ...nlLocale, ...customLocaleText }}
      dateAdapter={AdapterDateFns}
    >
      <StyledMuiDatePicker
        cancelLabel="Annuleren"
        inputRef={inputRef}
        className={classes.textField}
        disableFuture
        $color={color}
        valid={valid}
        openTo={openTo ? openTo : 'day'}
        $colorDanger={colorDanger}
        $black={black}
        format="dd-MM-yyyy"
        invalidDateMessage=""
        margin="normal"
        maxDateMessage=""
        $scaling={scaling}
        defaultValue={defaultValue}
        id="date-picker-inline"
        label="Schadedatum"
        value={defaultValue}
        onChange={handleChange}
        InputAdornmentProps={{
          classes: {
            root: classes.adornmentRoot,
          },
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        disabled={disabled}
        slotProps={
          {
            // inputLabel: {

            // },
            textField: {
              placeholder: 'dd-mm-jjjj',
              error: !valid,
              variant: 'standard',
              // invalidDateMessage: "",
              margin: "normal",
              required: true,
              classes: {
                root: classes.textField
              },
              InputLabelProps: {
                classes: {
                  root: classes.inputLabelRoot,
                  shrink: classes.inputLabelShrink,
                  asterisk: classes.inputLabelAsterisk,
                  formControl: classes.inputLabelFormControl,
                  focused: classes.focused,
                  disabled: classes.inputLabelDisabled,
                  error: classes.inputLabelError,
                },
              },
              // maxDateMessage: "De datum mag niet in de toekomst liggen.",
              InputProps: {
                classes: {
                  root: classes.inputRoot,
                  underline: classes.inputUnderline,
                  input: classes.inputInput,
                  disabled: classes.inputDisabled,
                  error: classes.inputError,
                },
                placeholder: 'dd-mm-jjjj',
              },
            }
          }
        }
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
